import mufaddal from "../../assets/images/team/mufaddal.jpg";
import anu from "../../assets/images/team/anu.jpg";
// import deepak from '../../assets/images/team/deepak.jpg'
import kanika from "../../assets/images/team/kanika.jpeg";
import vishwanth from "../../assets/images/team/vishwanth.jpeg";
import msanthosh from "../../assets/images/team/msanthosh.jpeg";
import nehaDhandhania from "../../assets/images/team/nehadhandhania.jpeg";
import maheshpoluju from "../../assets/images/team/mahesh_poloju.png";
import swapnildhok from "../../assets/images/team/swapnildhok.jpeg";
import seshunersu from "../../assets/images/team/sheshunersu.jpeg";
import sumansahu from "../../assets/images/team/sumansahu.jpeg";
import archana from "../../assets/images/team/archana.jpg";
export const items = [
  {
    Image: anu,
    name: "Anu Shadeja",
    role: "Founder",
    desc: "One of the most important assets of the company who has paved her way till here with hard work and natural affinity for managerial skills, who can solve every professional problem.",
    linkedIn: "in/anu-shadeja-79128716a/",
  },
  
  {
    Image: mufaddal,
    name: "Mufaddal",
    role: "Team Lead",
    desc: "Loves creating apps and focuses more on the look and feel and overall user experience. He is a big-time foodie, techie and loves sketching.",
    linkedIn: "in/mufaddalgulshan/",
  },
  {
    Image: archana,
    name: "Archana Tapadiya",
    role: "Software Developer",
    desc: "Loves coding and creating apps. Very enthusiastic, out-spoken and loves travelling and enjoys every bit of the challenges thrown",
    linkedIn: "in/archana-tapadiya/",
  },

  {
    Image: nehaDhandhania,
    name: "Neha Dhandhania",
    role: "Senior Business Analyst",
    desc: "An engine to innovation who makes profitable decisions with her deep-insight view and strategic problem solving skills.",
    linkedIn: "in/neha-dhandhania-683a6836/",
  },

];

export const teamHeadings = {
  title: "Team",
  description: "Our Rockstar Team",
};
